<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="12">
          <v-layout align-center fill-height>
            {{ $t("There is no total wear time set for contact lenses") }}
            <span class="ml-1">{{ validation.Product.Name }}</span>
          </v-layout>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      {{ $t("Enter the number of days that corresponds to this product:") }}
    </v-card-subtitle>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="duration"
            :label="$t('Amount of days')"
          ></v-text-field>
        </v-col>
      </v-row>

      <cardValdiationButtons
        :validation="validation"
        :duration="duration"
        :needsCancelButton="false"
        :successButtonName="$t('update gestation')"
        @deleteOutArray="$emit('deleteOutArray', $event)"
      />
    </v-card-text>
  </v-card>
</template>
  
  <script>
export default {
  props: ["validation"],
  data() {
    return {
      duration: null,
    };
  },
};
</script>
  
  <style>
</style>