var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.validations.length)?_c('v-row',_vm._l((_vm.getValidations),function(validation,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[(
          validation.FromCustomerType && validation.ToCustomerType != null
        )?[_c('clientTypeChangeCard',{attrs:{"validation":validation},on:{"deleteOutArray":function($event){return _vm.deleteOutArray($event)}}})]:(validation.FromAddress && validation.ToAddress != null)?[_c('clientAddressChangeCard',{attrs:{"validation":validation},on:{"deleteOutArray":function($event){return _vm.deleteOutArray($event)}}})]:(
          validation.FromAddress == null &&
          validation.ToAddress != null &&
          validation.Product == null
        )?[(_vm.getUpdateAddressCheck(validation))?[_c('clientAddNewAddress',{attrs:{"validation":validation},on:{"deleteOutArray":function($event){return _vm.deleteOutArray($event)}}})]:[_c('clientAddressUpdate',{attrs:{"validation":validation},on:{"deleteOutArray":function($event){return _vm.deleteOutArray($event)}}})]]:(
          validation.Product !== null &&
          validation.Product.Id !== null &&
          (validation.Customer == null || validation.Customer.Id == null)
        )?[_c('clientGestationContactLensesCard',{attrs:{"validation":validation},on:{"deleteOutArray":function($event){return _vm.deleteOutArray($event)}}})]:_vm._e()],2)}),1):(!_vm.loadingValidations)?_c('v-row',[_c('p',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.$t("There are currently no more validation conflicts."))+" ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }